/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
// Boolean indicating whether this is an article:
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/Layout";
const MDXLayout = Layout;
import Seo from "../components/Seo";
export const Head = props => React.createElement(Seo, {
  title: props.pageContext.frontmatter.title,
  description: props.pageContext.frontmatter.description,
  image: props.pageContext.frontmatter.image,
  article: true
});
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Création de produits avec notre service moule intégré"), "\n", React.createElement(_components.p, null, "Chez Laboratoires Pichot, nous partons toujours du vrai besoin client."), "\n", React.createElement(_components.p, null, "Nos clients créent des gammes de produits cohérentes, pertinentes et\r\nattirantes pour le consommateur."), "\n", React.createElement(_components.p, null, "Le désir d'acheter est souvent\r\ninfluencé par le packaging. Ainsi, notre rôle est de vous accompagner\r\ndans la création de gammes de pack ajusté précisemment à vos besoins."), "\n", React.createElement(_components.p, null, "C'est cette fabrication de flacon à façon qui nous caractérise. Nous\r\nfabriquons en France 100% de nos flacons plastiques dans notre site de\r\nproduction unique localisé dans le territoire du Livradois Forez, mais\r\naussi nous concevons et fabriquons en interne nos moules. C'est cette\r\nintégration en interne qui explique en partie notre grande réactivité."), "\n", React.createElement(_components.p, null, "En moyenne, nous fabriquons un moule par semaine, et ce depuis 30 ans.\r\nNous créeons 95% de nos moules dans nos standards. Les 5% restants sont\r\nles moules exclusifs que nous fabriquons spécifiquement pour certains\r\nproduits avec un besoin de démarcation forte (une forme exclusive), ou\r\npour porter le nom d'une marque."));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
